class API {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  static async get(url) {
    const response = await fetch(url);
    if (response.status === 403) {
      return Promise.reject("Invalid token");
    }
    return response.json();
  }

  static async post(url, data) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  async login(username, password, eulaAccepted) {
    return API.post(`${this.baseURL}/login`, {
      email: username,
      password,
      ...{ is_eula_accepted: eulaAccepted },
    });
  }

  async getApiKey(userId, token) {
    const path = `${this.baseURL}/user/${userId}/key?token=${token}`;
    return API.get(path);
  }
}

export default API;
