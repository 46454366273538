import { Point, Rectangle } from "./types";
import  Chunk from "./chunk";

export const drawGrid = (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point, zoom: number, baseGridSize: number = 50) => {
  const zoomedGridSize = baseGridSize * zoom;
  const numHorizontalLines = Math.ceil(width / zoomedGridSize);
  const numVerticalLines = Math.ceil(height / zoomedGridSize);

  const gridOffsetX = offset.x % zoomedGridSize;
  const gridOffsetY = offset.y % zoomedGridSize;

  ctx.save();

  ctx.strokeStyle = "#1f1faf";
  ctx.lineWidth = 0.5;

  // Draw the horizontal lines
  for (let i = 0; i < numVerticalLines; i++) {
    ctx.beginPath();
    ctx.moveTo(0, i * zoomedGridSize + gridOffsetY);
    ctx.lineTo(width, i * zoomedGridSize + gridOffsetY);
    ctx.stroke();
  }

  // Draw the vertical lines
  for (let i = 0; i < numHorizontalLines; i++) {
    ctx.beginPath();
    ctx.moveTo(i * zoomedGridSize + gridOffsetX, 0);
    ctx.lineTo(i * zoomedGridSize + gridOffsetX, height);
    ctx.stroke();
  }

  ctx.restore();
}

export const drawLineOnChunk = (ctx: CanvasRenderingContext2D, zoom: number, chunk: Chunk, color: string, drawRectangle: boolean) => {
  // Save the current state of the canvas
  ctx.save();
  
  // Set the line width
  ctx.lineWidth = 2;

  if (drawRectangle) {
    // Draw a rectangle on the chunk using the chunkBoundaries
    const width = chunk.chunkBoundaries.size.width * zoom;
    const height = chunk.chunkBoundaries.size.height * zoom;
    ctx.strokeStyle = color;
    ctx.strokeRect(
      chunk.chunkBoundaries.startPoint.x * zoom,
      chunk.chunkBoundaries.startPoint.y * zoom,
      width,
      height
    );
  }
  // Draw a line on the chunk using the imageCameraPosition points
  
  const frameData = chunk.frames;

  ctx.beginPath();
  ctx.moveTo(
    frameData[0].imageCameraPosition[0] * zoom,
    frameData[0].imageCameraPosition[1] * zoom
  );

  for (let j = 1; j < frameData.length; j += 1) {
    ctx.lineTo(
      frameData[j].imageCameraPosition[0] * zoom,
      frameData[j].imageCameraPosition[1] * zoom
    );
  }
  ctx.strokeStyle = color;
  ctx.stroke();

  // Draw a colored circle at the first point
  ctx.beginPath();
  ctx.arc(
    frameData[0].imageCameraPosition[0] * zoom,
    frameData[0].imageCameraPosition[1] * zoom,
    5,
    0,
    2 * Math.PI,
    false
  );
  ctx.fillStyle = color;
  ctx.fill();

  ctx.translate(
    chunk.rotationPoint.x * zoom,
    chunk.rotationPoint.y * zoom
  );

  // Rotate the canvas by the chunk's rotation
  ctx.rotate(chunk.globalRotation * (Math.PI / 180));

  // Translate back
  ctx.translate(
    -chunk.rotationPoint.x * zoom,
    -chunk.rotationPoint.y * zoom
  );

  // Restore the canvas to its original state
  ctx.restore();
}
  
export const drawCameraFrustum = (ctx: CanvasRenderingContext2D, zoom: number, chunk: Chunk, frameNumber: number) => {

    const imageCameraPosition =
     chunk.frames[frameNumber - chunk.firstFrameNumber].imageCameraPosition;

    const cameraAngleDeg =
      chunk.frames[frameNumber- chunk.firstFrameNumber].imageCameraRotation;
    const [x, y] = imageCameraPosition;

    ctx.save();

    ctx.translate(chunk.position.x * zoom, chunk.position.y * zoom);

    ctx.translate(
      chunk.rotationPoint.x * zoom,
      chunk.rotationPoint.y * zoom
    );

    // Rotate the canvas by the chunk's rotation
    ctx.rotate(chunk.globalRotation * (Math.PI / 180));

    // Translate back
    ctx.translate(
      -chunk.rotationPoint.x * zoom,
      -chunk.rotationPoint.y * zoom
    );

    // Set the line width
    ctx.lineWidth = 2;

    // draw dot as current time indicator
    ctx.beginPath();
    ctx.arc(x * zoom, y * zoom, 5, 0, 2 * Math.PI, false);
    ctx.fillStyle = "black";
    ctx.fill();

    // draw a line from the dot to the camera direction

    const cameraRotation = cameraAngleDeg * (Math.PI / 180);

    ctx.beginPath();
    ctx.moveTo(x * zoom, y * zoom);

    ctx?.lineTo(
      x * zoom + 200 * Math.cos(cameraRotation),
      y * zoom + 200 * Math.sin(cameraRotation)
    );
    ctx.stroke();

    // draw frustum
  
    // Calculate the direction of the two lines
    const fov = Math.PI / 3; // 60 degrees in radians
    const line1Direction = cameraRotation - fov / 2;
    const line2Direction = cameraRotation + fov / 2;

    // Draw the two lines
    ctx.beginPath();
    ctx.moveTo(x * zoom, y * zoom);
    
    ctx.lineTo(
      x * zoom + 200 * Math.cos(line1Direction),
      y * zoom + 200 * Math.sin(line1Direction)
    );
    ctx.strokeStyle = "blue";
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x * zoom, y * zoom);
    ctx.lineTo(
      x * zoom + 200 * Math.cos(line2Direction),
      y * zoom + 200 * Math.sin(line2Direction)
    );
    ctx.strokeStyle = "red";
    ctx.stroke();

    ctx.restore();
  }