import Cookie from "js-cookie";

import ImageChunkMover from "./imageChunkMover.js";
import { FileUploader } from "./fileUploader.js";
import API from "./api.js";
import { Point } from "./types.js";

const isMoveKey = (key: string): boolean => {
  return ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(key);
};

let rightMouseDown = false;

let opacityIndex = 0;
let opacityValues = [0.5, 0.05, 1];

const attachEventListeners = (mover) => {
  document.addEventListener("keydown", (event) => {
    const shiftPressed = event.shiftKey;
    const ctrlPressed = event.ctrlKey;
    // Handle arrow key presses
    if (isMoveKey(event.key)) {
      event.preventDefault();
      // If there is a selected chunk we move it.
      if (mover.isMovingChunk()) {
        const moveAmount = shiftPressed ? 1 : 10;
        const dx =
          event.key === "ArrowRight"
            ? moveAmount
            : event.key === "ArrowLeft"
            ? -moveAmount
            : 0;
        const dy =
          event.key === "ArrowDown"
            ? moveAmount
            : event.key === "ArrowUp"
            ? -moveAmount
            : 0;

        mover.moveSelectedChunk(dx, dy);
      } else {
        // Otherwise pan the canvas
        if (event.key === "ArrowRight") mover.panXBy(-10);
        if (event.key === "ArrowLeft") mover.panXBy(10);
        if (event.key === "ArrowUp") mover.panYBy(10);
        if (event.key === "ArrowDown") mover.panYBy(-10);
      }
    }

    // Handle Rotation events
    if (event.code === "Comma" || event.code === "Period") {
      if (mover.isMovingChunk()) {
        event.preventDefault();
        const rotationAmount = shiftPressed ? 22.5 : 0.5;
        mover.rotateSelectedChunk(
          event.code === "Comma" ? -rotationAmount : rotationAmount
        );
      }
    }

    // Handle zoom events
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
      mover.zoomToCenter(event.key === "+" ? 0.1 : -0.1);
      updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
    }

    // Handle chunk selection events
    if (event.key === "z" || event.key === "x") {
      event.preventDefault();

      if (event.key === "z") {
        const [floorIndex, chunk] = mover.selectPrevChunk();
        updateInfobox(floorIndex, chunk, mover);
        updateVideo(chunk.firstFrameNumber, mover);
      } else {
        const [floorIndex, chunk] = mover.selectNextChunk();
        updateInfobox(floorIndex, chunk, mover);
        updateVideo(chunk.firstFrameNumber, mover);
      }
    }

    // Split chunk
    if (event.key === "s") {
      showLoadingModal();
      event.preventDefault();
      
      setTimeout(() => {
        mover.splitChunk();
        hideLoadingModal();
        updateDebugPanel(mover);
      }, 100);
    }

    // toggle videoelement visibiility
    if (event.key === "v") {
      const videoContainer = document.getElementById("video-container");
      if (videoContainer.style.display === "block") {
        videoContainer.style.display = "none";
      } else {
        videoContainer.style.display = "block";
      }
    }

    // Handle video control events
    let key = event.key.toLowerCase();
    if (key === "h") {
      const currentFrame = mover.nextFrame(shiftPressed ? 10 : 1);
      updateVideo(currentFrame, mover);
    } else if (key === "g") {
      const currentFrame = mover.prevFrame(shiftPressed ? 10 : 1);
      updateVideo(currentFrame, mover);
    } else if (key === "j") {
      mover.togglePlay(shiftPressed ? 5 : 1, (currentFrame) => {
        updateVideo(currentFrame, mover);
      });
    }

    // Handle deselection event
    if (event.key === "Escape") {
      event.preventDefault();
      mover.deselectChunk();
    }

    // Handle floor selection event
    if (shiftPressed && event.code.startsWith("Digit")) {
      const floorIndex = parseInt(event.code.slice(-1)) - 1;
      const currentFrame = mover.selectFloor(floorIndex);
      updateVideo(currentFrame, mover);
      updateInfobox(floorIndex, mover.selectedChunk, mover);
    }

    // Handle debug panel toggle
    if (ctrlPressed) {
      if (event.key === "i") {
        toggleDebugPanel();
      }
    }
    if (event.key === "t") {
      opacityIndex = (opacityIndex + 1) % opacityValues.length;
      mover.opacity = opacityValues[opacityIndex];
      mover.redrawChunks(); // Draws now all the chunks, should only update the current chunk maybe.
    }
    if (event.key === "u") {
      mover.coloring = mover.coloring ? false : true;
      mover.redrawChunks();
    }

    updateDebugPanel(mover);
  });

  // Throttle function
  function throttle(func, limit) {
    let inThrottle;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }

  // Throttled wheel event handler
  const throttledWheelHandler = throttle((event) => {
    event.preventDefault();
    const zoomSpeed = event.deltaMode === 1 ? 0.001 : 0.01; // slower zoom for mouse
    const factor = Math.pow(1.1, -event.deltaY * zoomSpeed);
    let rect = mover.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    if (x < 0 || y < 0) {
      return;
    }
    mover.zoomToPoint(factor, x, y);
    updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
  }, 50); // Adjust this value as needed

  // Add event listener
  document.addEventListener(
    "wheel",
    (event) => {
      event.preventDefault();
      throttledWheelHandler(event);
    },
    { passive: false }
  );

  document.addEventListener("mousemove", (event) => {
    if (rightMouseDown) {
      let rect = mover.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const point = new Point(x, y);
      mover.panWith(point);
    }
  });

  document.addEventListener("mouseup", (event) => {
    if (event.button === 2) {
      // Right mouse button
      rightMouseDown = false;
      mover.panStop();
    }
  });

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  document.addEventListener("mousedown", (event) => {
    event.preventDefault();
    if (event.button === 2) {
      rightMouseDown = true;
      let rect = mover.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const point = new Point(x, y);
      mover.panStart(point);
    }

    if (event.button === 0 && event.altKey && event.shiftKey) {
      let rect = mover.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      let screenPoint = new Point(x, y);
      const currentFrame = mover.selectPathPoint(screenPoint);
      updateVideo(currentFrame, mover);
    }
  });
};

function showLoadingModal() {
  document.getElementById('loading-modal').style.display = 'flex';
}

function hideLoadingModal() {
  document.getElementById('loading-modal').style.display = 'none';
}

const updateVideo = (frameNumber, mover) => {
  const frameIndicator = document.getElementById("frame-indicator");
  frameIndicator.textContent = `Frame: ${frameNumber} `;
  const chunkIndicator = document.getElementById("chunk-indicator");
  chunkIndicator.textContent = `Chunk: ${mover.data.arKitData.frames[frameNumber].chunk}`;
  const deviceModelIndicator = document.getElementById(
    "device-model-indicator"
  );
  deviceModelIndicator.textContent = `Device Model: ${mover.data.arKitData.phoneMarketName}, ${mover.data.arKitData.phoneModel}`;

  const elevationCanvas = document.getElementById("elevation-canvas");
  const ctx = elevationCanvas.getContext("2d");
  // Draw elevation curve
  ctx.fillStyle = "#fffdd0";
  ctx.fillRect(0, 0, elevationCanvas.width, elevationCanvas.height);

  // get elevation data and gather min and max values
  const elevationData = [];
  let min = Infinity;
  let max = -Infinity;
  mover.data.arKitData.frames.forEach((frame) => {
    const elevation = frame.cameraTransform[3][1] * 100;
    elevationData.push(elevation);
    min = Math.min(min, elevation);
    max = Math.max(max, elevation);
  });

  // Draw elevation curve
  ctx.beginPath();
  ctx.strokeStyle = "#000";
  ctx.lineWidth = 1;
  const width = elevationCanvas.width;
  const height = elevationCanvas.height;
  const step = (width) / elevationData.length;
  ctx.moveTo(0, height - 10);
  elevationData.forEach((elevation, i) => {
    const x = i * step + 10;
    const y = height - 10 - ((elevation - min) / (max - min)) * (height - 20);
    ctx.lineTo(x, y);
  });
  ctx.stroke();

  // Draw current frame indicator
  ctx.beginPath();
  ctx.strokeStyle = "#f00";
  ctx.lineWidth = 1;
  const x = (frameNumber / elevationData.length) * (width);
  ctx.moveTo(x, 0);
  ctx.lineTo(x, height);
  ctx.stroke();
};

const getFloorIndicator = (floorIndex, mover) => {
  const floors = [];
  for (let i = 0; i < mover.chunkManager.getFloorAmount(); i += 1) {
    if (i === floorIndex) {
      floors.push(`<span id="selectedFloor">${i + 1}</span>`);
    } else {
      floors.push(`<span id="floor">${i + 1}</span>`);
    }
  }
  return floors.join(" | ");
};

const updateInfobox = (floorIndex, chunk, mover) => {
  const ui = document.getElementById("ui");
  const infobox = document.getElementById("infobox");
  ui.style.visibility = "visible";
  infobox.innerHTML = `Floor: ${getFloorIndicator(
    floorIndex,
    mover
  )} Selected chunk: ${chunk ? mover.chunkManager.getIndexForChunk(chunk) : "None"} Zoom: ${Math.round(
    mover.zoom * 100
  )}%`;
};

const attachButtonListeners = (mover) => {
  const nextChunkButton = document.getElementById("nextChunk");
  nextChunkButton.addEventListener("click", () => {
    const [floorIndex, chunk] = mover.selectNextChunk();
    updateInfobox(floorIndex, chunk, mover);
    updateDebugPanel(mover);
  });

  const previousChunkButton = document.getElementById("prevChunk");
  previousChunkButton.addEventListener("click", () => {
    const [floorIndex, chunk] = mover.selectPrevChunk();
    updateInfobox(floorIndex, chunk, mover);
    updateDebugPanel(mover);
  });

  const exportButton = document.getElementById("export");
  exportButton.addEventListener("click", () => {
    showLoadingModal()
    setTimeout(() => {
      const arkitData = mover.exportData();
      const data = JSON.stringify(arkitData);
      const blob = new Blob([data], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "arkitData.json";
      a.click();
      hideLoadingModal()
    }, 100);
  });
};

const toggleDebugPanel = () => {
  const debugPanel = document.getElementById("debug-panel");
  if (debugPanel.style.display === "none" || debugPanel.style.display === "") {
    debugPanel.style.display = "block";
  } else {
    debugPanel.style.display = "none";
  }
};

const updateDebugPanel = (mover) => {
  const chunkInfoDiv = document.getElementById("chunk-info");
  const chunks = mover.chunkManager.getAllChunksInFloor(mover.currentFloor);
  
  const debugTableRow = (label, value) => {
    return `<tr><td>${label}</td><td>${value}</td></tr>`;
  };

  let html = "<table><thead>";
  chunks.forEach((chunk) => {
    html += `<tr><th>Chunk ${mover.chunkManager.getIndexForChunk(chunk)}, f:${chunk.floor} c:${chunk.frames[0].chunk}</th></tr></thead><tbody>`;
    html += debugTableRow("Coordinates", chunk.position.toString());
    html += debugTableRow(
      "PathStartPoint",
      chunk.pathStartPosition.toString()
    );
    html += debugTableRow(
      "GlobalRotation",
      `${chunk.globalRotation.toFixed(1)}°`
    );
    html += debugTableRow("Rotation", `${chunk.rotation.toFixed(1)}°`);
    html += debugTableRow(
      "Shift",
      chunk.shift.toString()
    );
  });
  html += "</tbody></table>";
  chunkInfoDiv.innerHTML = html;
};

const start = () => {
  const dropArea = document.getElementById("drop-area");
  dropArea.style.visibility = "visible";
  const uploader = new FileUploader(document, async (zip) => {
    // Use zip here and pass it to ImageChunkMover if necessary
    showLoadingModal()

    setTimeout(async () => {
      const mover = new ImageChunkMover(zip);
      await mover.init();
      updateDebugPanel(mover);
      document.body.appendChild(mover.canvas);
      updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
      updateVideo(0, mover);
      attachButtonListeners(mover);
      attachEventListeners(mover);
      document.getElementById("video-container").style.display = "block";
      hideLoadingModal();
    }, 100);
  });
};

window.onload = () => {
  const api = new API("https://api.cubi.casa");
  const loginDialog = document.getElementById("login-dialog");

  // Handle login by locally stored or temporary cookie token
  let token;
  let tokenExpiration;
  let userId;

  if (Cookie.get("cc_temp_login_token")) {
    token = Cookie.get("cc_temp_login_token");
    userId = Cookie.get("cc_temp_user_id");
    tokenExpiration = new Date().getTime() + 10 * 60 * 1000; // 10 minutes

    // Remove temporary cookie
    Cookie.remove("cc_temp_login_token");
    Cookie.remove("cc_temp_user_id");

    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
    localStorage.setItem("tokenExpiration", tokenExpiration);
  } else {
    token = localStorage.getItem("token");
    tokenExpiration = localStorage.getItem("tokenExpiration");
    userId = localStorage.getItem("userId");
  }
  if (token && tokenExpiration > Date.now()) {
    api
      .getApiKey(userId, token)
      .then((response) => {
        if (response.error) {
          alert("Automatic login failed. Please use your credentials.");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("tokenExpiration");
          return;
        }
        loginDialog.style.display = "none";

        start();
        return;
      })
      .catch((error) => {
        console.warn("Error during automatic login:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("tokenExpiration");
      });
  } else {
    const usernameInput = document.getElementById("username");
    const passwordInput = document.getElementById("password");
    const eulaCheckbox = document.getElementById("eula");
    document.querySelector("form").addEventListener("submit", function (event) {
      event.preventDefault();

      const username = usernameInput.value;
      const password = passwordInput.value;
      const eulaAccepted = eulaCheckbox.checked;

      api.login(username, password).then((response) => {
        if (response.error) {
          alert("Login failed");
          usernameInput.value = "";
          passwordInput.value = "";
          eulaCheckbox.checked = false;
          return;
        }
        loginDialog.style.display = "none";

        localStorage.setItem("token", response.token);
        localStorage.setItem("userId", response.user.id);
        localStorage.setItem("tokenExpiration", Date.now() + 86400000);

        start();
      });
    });
  }
};
