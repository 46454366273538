import { Point, Rectangle } from './types';
import { IndexData } from './interfaces';

class Chunk {
  position: Point;
  width: number;
  height: number;
  globalRotation: number;
  canvas: HTMLCanvasElement;
  greenCanvas: HTMLCanvasElement | null;
  redCanvas: HTMLCanvasElement | null;
  pathStartPosition: Point;
  rotationPoint: Point;
  floor: number;
  chunkBoundaries: Rectangle; // Replace 'any' with the actual type
  firstFrameNumber: number;
  shift: Point;
  rotation: number;
  frames: any;
  frameMap: any;

  constructor(
    imageWidth: number,
    imageHeight: number,
    chunkCanvas: HTMLCanvasElement,
    chunkBoundaries: Rectangle, 
    frames: any,
    frameMap: IndexData
  ) {
    this.position = Point.zero();
    this.width = imageWidth;
    this.height = imageHeight;
    this.globalRotation = 0;
    this.canvas = chunkCanvas;
    this.greenCanvas = null;
    this.redCanvas = null;
    this.chunkBoundaries = chunkBoundaries;
    this.frames = frames;
    this.frameMap = frameMap;

    const pathStartPoint = frames[0].imageCameraPosition;
    this.pathStartPosition = Point.fromArray(pathStartPoint);
    this.rotationPoint = Point.fromArray(pathStartPoint);
            
    this.firstFrameNumber = frames[0].frameNumber;
    this.floor = frames[0].floorNumber;

    this.shift = Point.zero();
    this.rotation = 0;
  }

  moveBy(dx: number, dy: number, isShift: boolean): void {
    const delta = new Point(dx, dy);
    this.position = this.position.add(delta);

    this.pathStartPosition = this.pathStartPosition.add(new Point(dx, dy));

    if (isShift) {
      this.shift = this.shift.add(new Point(dx, dy));
    }
  }

  calculatePositionForRotation(rotationPoint: Point, point: Point, angle: number): Point {
    const delta = point.subtract(rotationPoint);
    return rotationPoint.add(delta.rotate(angle));
  }

  rotateAboutPoint(rotationPoint: Point, angle: number, isShift: boolean): void {
    const rotatedPoint = this.calculatePositionForRotation(rotationPoint, this.position, angle);
    this.position = rotatedPoint;
    this.globalRotation = (this.globalRotation + angle) % 360;
    this.rotationPoint = Point.zero();
    this.pathStartPosition = this.calculatePositionForRotation(
      rotationPoint,
      this.pathStartPosition,
      angle
    );

    if (isShift) {
      this.rotation += angle;
    }
  }

  split(splitFrameNumber: number): Chunk {
    const newFrames = this.frames.filter((frame: any) => frame.frameNumber >= splitFrameNumber);
    const oldFrames = this.frames.filter((frame: any) => frame.frameNumber < splitFrameNumber);

    const oldCanvas = document.createElement('canvas');
    oldCanvas.width = this.canvas.width;
    oldCanvas.height = this.canvas.height;
    const oldContext = oldCanvas.getContext('2d', { willReadFrequently: true });

    const newCanvas = document.createElement('canvas');
    newCanvas.width = this.canvas.width;
    newCanvas.height = this.canvas.height;
    const newContext = newCanvas.getContext('2d', { willReadFrequently: true });
 
    const oldFrameMap: IndexData = { width: this.frameMap.width, height: this.frameMap.height, frameIndices: []}
    const newFrameMap: IndexData = { width: this.frameMap.width, height: this.frameMap.height, frameIndices: []}

    const ctx = this.canvas.getContext('2d', { willReadFrequently: true });

    for (let i = 0; i < this.frameMap.height; i += 1) {
      for (let j = 0; j < this.frameMap.width; j += 1) {
        const index = i * this.frameMap.width + j;
        const frameNumber = this.frameMap.frameIndices[index];
        if (frameNumber !== 0) {
            const imageData = ctx.getImageData(j, i, 1, 1);
          if (frameNumber < splitFrameNumber) {
            oldContext.putImageData(imageData, j, i);
            oldFrameMap.frameIndices.push(frameNumber)
            newFrameMap.frameIndices.push(0)
          } else {    
            newContext.putImageData(imageData, j, i);
            newFrameMap.frameIndices.push(frameNumber)
            oldFrameMap.frameIndices.push(0)
          }
        } else {
          oldFrameMap.frameIndices.push(0)
          newFrameMap.frameIndices.push(0)
        }
      }
    }

    this.frames = oldFrames;
    this.canvas = oldCanvas
    this.frameMap = oldFrameMap
    this.redCanvas = null;
    this.greenCanvas = null;

    const newChunk = new Chunk(
      this.width,
      this.height,
      newCanvas,
      this.chunkBoundaries,
      newFrames,
      newFrameMap
    );
    newChunk.rotateAboutPoint(this.rotationPoint, this.globalRotation, false);
    newChunk.moveBy(this.position.x, this.position.y, false);
    return newChunk;
  }
}

export default Chunk;