import JSZip from "jszip";

import { IndexData, FrameData } from "./interfaces";

function transformChunkData(chunkDataJson) {
  const chunkDataArray = [];
  Object.values(chunkDataJson.dataChunks).forEach((floor) => {
    const sortedChunks = Object.entries(floor).sort((a, b) => a[0] - b[0]);

    const chunksOnFloor = [];
    sortedChunks.forEach(([index, chunk], i, arr) => {
      // Check if chunk indexes are consecutive
      if (i > 0 && index - arr[i - 1][0] !== 1) {
        console.warn(
          `Chunk indexes are not consecutive: ${arr[i - 1][0]} and ${index}`
        );
        const emptyChunkData = {
          imageFile: "",
          boundingBox: { x: 0, y: 0, width: 0, height: 0 },
        };
        chunksOnFloor.push(emptyChunkData);
      }

      const parsedChunkData = {
        ...chunk,
        boundingBox: {
          x: chunk.boundingBox.minx,
          y: chunk.boundingBox.miny,
          width: chunk.boundingBox.maxx - chunk.boundingBox.minx,
          height: chunk.boundingBox.maxy - chunk.boundingBox.miny,
        },
      };
      chunksOnFloor.push(parsedChunkData);
    });

    chunkDataArray.push(chunksOnFloor);
  });

  return chunkDataArray;
}

const parseData = async (automationData: any) => {
  try {
    const configText = await automationData.file("config.json").async("text");
    const config = JSON.parse(configText);

    // Unzip the chunk_data
    const chunkDataBlob = await automationData
      .file(config.chunk_data)
      .async("blob");

    const chunkDataFiles = await JSZip.loadAsync(chunkDataBlob);

    const chunkDataJsonText = await chunkDataFiles
      .file("chunkData.json")
      .async("text");
    let chunkDataJson = JSON.parse(chunkDataJsonText);

    // Transform chunkDataJson to chunkDataArray
    const chunkDataArray = transformChunkData(chunkDataJson);

    const floorImages: Blob[][]= [];
    const indexMaps: Blob[][] = [];

    const loadPromises = Object.entries(chunkDataJson.dataChunks).flatMap(
      ([floor, chunks]) => {
        floorImages[parseInt(floor, 10)] = [];
        indexMaps[parseInt(floor, 10)] = [];
        return Object.entries(chunks).map(([chunk, chunkData]) => {
          const imageFile = chunkData.imageFile;
          const indexMapFile = chunkData.indexFile;
          return [chunkDataFiles.files[imageFile].async("blob").then((content) => {
            floorImages[floor][chunk] = content;
          }), 
          chunkDataFiles.files[indexMapFile].async("blob").then((content) => {
            indexMaps[floor][chunk] = content
          })
        ];

        });
      }
    );

    let results;
    try {
      results = await Promise.all([
        ...loadPromises,
        automationData.files[config.video_lowres[0]].async("blob"),
        automationData.files[config.arKit[0]].async("text"),
      ]);
    } catch (error) {
      console.error("Error during Promise.all:", error);
      throw error;
    }

    let arKitData, videoFile;
    try {
      videoFile = results[results.length - 2];
      arKitData = JSON.parse(results[results.length - 1]);
    } catch (error) {
      console.error("Error during JSON parsing:", error);
      throw error;
    }

    const parsedIndexMaps = await parseIndexMaps(indexMaps);

    const data = {
      floors: floorImages,
      indexMaps: parsedIndexMaps,
      arKitData,
      videoFile,
      chunkData: chunkDataArray, // use transformed chunkData
    };
    return data;
  } catch (error) {
    console.error("Error in parseData:", error);
  }
};

const parseIndexMaps = (indexMaps: Blob[][]): Promise<IndexData[][][]> => {
  const parsedIndexMaps: IndexData[][][] = [];

  const promises = indexMaps.map((floor, i) => {
    parsedIndexMaps[i] = [];
    return Promise.all(floor.map((blob, j) => {
      return blob.arrayBuffer().then(arrayBuffer => {
        const dataView = new DataView(arrayBuffer);
        const width = dataView.getUint16(0, true);
        const height = dataView.getUint16(2, true);
        const frameIndices: number[] = [];
        for (let i = 4; i < dataView.byteLength; i += 2) {
          frameIndices.push(dataView.getUint16(i, true));
        }
        parsedIndexMaps[i][j] = { width, height, frameIndices };
      });
    }));
  });

  return Promise.all(promises.flat()).then(() => parsedIndexMaps);
};

export default parseData;
