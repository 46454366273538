import { getChunkColor } from "./drawUtils.js";
import Chunk from "./chunk.js";
import ChunkManager from "./chunkManager.js";
import parseData from "./dataParser.js";
import Video from "./video.js";
import DataCalculator from "./dataCalculator.js";
import { screenToCanvas, canvasToScreen } from "./calculations.js";
import { Point, Direction, Rectangle } from './types'; // Assuming Point class is in Point.ts
import { ImageData } from './interfaces';
import { drawGrid, drawLineOnChunk, drawCameraFrustum } from "./lineDrawing.js";
import { FrameData } from "./interfaces";
class ImageChunkMover {
  chunkManager: ChunkManager;
  selectedChunk: Chunk | null; // Assuming Chunk class is defined somewhere
  dragging: boolean;
  panning: boolean;
  zoom: number;
  offset: Point;
  dragStart: Point;
  data: any;
  currentFloor: number;
  opacity: number;
  coloring: boolean;
  currentFrame: number;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  automationData: any; 
  dataCalculator: DataCalculator;
  videoController: Video | null;

  constructor(automationData: any) { 
    this.chunkManager = new ChunkManager();
    this.selectedChunk = null;
    this.dragging = false;
    this.panning = false;
    this.zoom = 1;
    this.offset = Point.zero();
    this.dragStart = Point.zero()
    this.data = null;
    this.currentFloor = 0;
    this.opacity = 0.5;
    this.coloring = true;
    this.currentFrame = 0;

    this.canvas = document.getElementById("canvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.ctx = this.canvas.getContext("2d", { willReadFrequently: true });
    this.automationData = automationData;
    this.dataCalculator = new DataCalculator();
    this.videoController = null;
  }

  async init() {
    const data = await parseData(this.automationData);
    this.data = data;

    await this.loadChunkImages();
    // Load the floor of the first floor.
    this.currentFloor = this.data.arKitData.frames[0].floorNumber;
    this.videoController = new Video(this.data.videoFile, this.data.arKitData);
    this.redrawChunks();
  }

  frameChanged() {
    if (this.selectedChunk) {
      const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
      if (this.selectedChunk !== frameChunk) {
        this.selectedChunk = frameChunk;
        this.currentFloor = frameChunk.floor
      }
    }
  }

  nextFrame(frameAmount: number): number {
    this.currentFrame = this.videoController?.nextFrame(frameAmount) ?? this.currentFrame;
    this.frameChanged();
    this.redrawChunks();
    return this.currentFrame;
  }

  prevFrame(frameAmount: number): number {
    this.currentFrame = this.videoController?.prevFrame(frameAmount) ?? this.currentFrame;
    this.frameChanged();
    this.redrawChunks();
    return this.currentFrame;
  }
  togglePlay(multiplier:number, callback: (frame: number) => void) {
    this.videoController?.togglePlay(multiplier, () => {
      this.currentFrame = this.videoController?.currentFrame ?? this.currentFrame;
      this.frameChanged();
      this.redrawChunks();
      callback(this.currentFrame);
    });
  }

  splitChunk() {
    if (this.selectedChunk === null) {
      this.selectChunk(this.chunkManager.getChunkByFrame(this.currentFrame)!);
    }
    if (this.selectedChunk === null || this.selectedChunk.frames[0].frameNumber === this.currentFrame) {
      return
    }
    this.selectedChunk = this.chunkManager.splitChunk(this.selectedChunk!, this.currentFrame);
    this.redrawChunks();
  }

  loadImage(file: Blob): Promise<object> {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file); // create a URL for the Blob
      const img = new Image();
      img.src = url;
      img.onload = () => {
        // Create a new canvas to draw the image on
        const chunkCanvas = document.createElement("canvas");
        chunkCanvas.width = img.width;
        chunkCanvas.height = img.height;
        const chunkCtx = chunkCanvas.getContext("2d", { willReadFrequently: true });
        chunkCtx?.drawImage(img, 0, 0, img.width, img.height);

        resolve({ imageWidth: img.width, imageHeight: img.height, chunkCanvas });
      };

      img.onerror = (err) => {
        console.error(`Error loading image ${file}:`, err);
        reject(err);
      };
    });
  }

  async loadChunkImages() {
    return new Promise((resolve) => {
      if (!this.data) {
        console.error("No data");
        return;
      }
      const framesMap: Record<string, FrameData> = {};

      // Preprocess frames into a map
       this.data.arKitData.frames.forEach((frame) => {
        const key = `${frame.floorNumber}-${frame.chunk}`;
        if (!framesMap[key]) {
          framesMap[key] = [];  
        }
        framesMap[key].push(frame);
      });
      // Asynchronously load all the images
    const loadPromises: Promise<any>[] = [];  
    for (let i = 0; i < this.data.floors.length; i += 1) {
        this.data.floors[i].forEach((file, chunkIndex) => {
          loadPromises.push(
            (async () => {
              const { imageWidth, imageHeight, chunkCanvas } =
                await this.loadImage(file);
          
              const key = `${i}-${chunkIndex}`;
              return {
                imageWidth,
                imageHeight,
                chunkCanvas,
                chunkBoundaries: this.data.chunkData[i][chunkIndex].boundingBox,
                frames: framesMap[key],
                indexMap: this.data.indexMaps[i][chunkIndex],
              };
            })()
          );
        });
      }

      Promise.all(loadPromises).then((chunkData) => {
        // Order chunks based on the first frame number
        chunkData.sort((a, b) => a.frames[0].frameNumber - b.frames[0].frameNumber);
        chunkData.forEach(
          (
            {
              imageWidth,
              imageHeight,
              chunkCanvas,
              chunkBoundaries,
              frames,
              indexMap
            }
          ) => {
            this.chunkManager.addChunk(
              new Chunk(
                imageWidth,
                imageHeight,
                chunkCanvas,
                Rectangle.fromArray(chunkBoundaries),
                frames,
                indexMap
              )
            );
          }
        );

        resolve();
      });
    });
  }

  isMovingChunk() {
    return this.selectedChunk !== null;
  }

  panXBy(dx: number) {
    this.offset = this.offset.add(new Point(-dx, 0));
    this.redrawChunks();
  }

  panYBy(dy: number) {
    this.offset = this.offset.add(new Point(0, -dy));
    this.redrawChunks();
  }

  moveSelectedChunk(dx: number, dy: number) {
    this.selectedChunk?.moveBy(dx, dy, true);

    this.chunkManager.getChunksAfter(this.selectedChunk).forEach((chunk) => {
      chunk.moveBy(dx, dy, false);
    });

    this.redrawChunks();
  }

rotateSelectedChunk(rotation: number) {
  const rotationPoint = this.selectedChunk?.pathStartPosition;
  if (!rotationPoint) {
    return;
  }
  const consecutiveChunks = this.chunkManager.getChunksAfter(this.selectedChunk);
  consecutiveChunks.forEach((chunk) => {
    chunk.rotateAboutPoint(rotationPoint, rotation, false);
  });

  this.selectedChunk?.rotateAboutPoint(rotationPoint, rotation, true);
  this.redrawChunks();
}

  panStart(startPoint: Point) {
    this.dragging = false;
    this.panning = true;
    this.dragStart = startPoint.copy();
  }

  panWith(point: Point) {
    const delta = point.subtract(this.dragStart)
    this.offset = this.offset.add(delta);
    this.dragStart = point;
    this.redrawChunks();
  }

  panStop() {
    this.panning = false;
  }

  deselectChunk() {
    this.selectedChunk = null;
    this.dragging = false;
    this.redrawChunks();
  }

  selectFloor(floorIndex: number): number {
    if (floorIndex < 0 || floorIndex >= this.chunkManager.getFloorAmount()) {
      return this.currentFloor;
    }
    this.currentFloor = floorIndex;
    this.currentFrame = this.chunkManager.getAllChunksInFloor(this.currentFloor)[0].firstFrameNumber;
    this.videoController?.seekToFrame(this.currentFrame);
    this.deselectChunk();
    return this.currentFloor
  }

  selectChunkDirection(direction: Direction) {
    if (this.selectedChunk === null) {
      this.selectChunk(this.chunkManager.getChunk(0));
      return [this.currentFloor, this.selectedChunk];
    }
    const allChunks = this.chunkManager.getAllChunks();
    let chunkIndex = this.chunkManager.getIndexForChunk(this.selectedChunk);
    if (direction === Direction.Next) {
      chunkIndex = (chunkIndex + 1) % allChunks.length;
    } else if (direction === Direction.Prev) {
      chunkIndex = (chunkIndex - 1 + allChunks.length) % allChunks.length;
    }
    const chunk = this.chunkManager.getChunk(chunkIndex);
    this.selectChunk(chunk)
    return [this.currentFloor, this.selectedChunk];
  }

  selectChunk(chunk: Chunk) {
    this.selectedChunk = chunk;
    this.currentFloor = this.selectedChunk.floor;
    this.videoController?.seekToFrame(this.selectedChunk.firstFrameNumber);
    this.currentFrame = this.selectedChunk.firstFrameNumber;
    this.focusSelectedChunk();
  }

  selectNextChunk() {
    return this.selectChunkDirection(Direction.Next);
  }

  selectPrevChunk() {
    return this.selectChunkDirection(Direction.Prev);
  }

  selectPathPoint(screenPoint: Point) {
    const zoomedPoint = screenToCanvas(
      screenPoint,
      this.offset,
      this.zoom
    );

    let closestFrame = -1;
    let closestDistanceSquared = Number.MAX_VALUE;
    this.data.arKitData.frames.forEach((frame) => {
      if (frame.floorNumber !== this.currentFloor) {
        return;
      }

      const chunk = this.chunkManager.getChunkByFrame(frame.frameNumber);
      if (!chunk) {
        return;
      }

      const imageCameraPosition = new Point(frame.imageCameraPosition[0], frame.imageCameraPosition[1]);
      const rotatedPoint = imageCameraPosition.rotate(chunk.globalRotation);
      const cameraPosition = rotatedPoint.add(chunk.position);
      const delta = zoomedPoint.subtract(cameraPosition.multiply(this.zoom));

      const distanceSquared = delta.x * delta.x + delta.y * delta.y;  
      if (distanceSquared < closestDistanceSquared) {
        closestDistanceSquared = distanceSquared;
        closestFrame = frame.frameNumber;
      }
    });
    this.currentFrame = closestFrame;
    this.frameChanged();
    this.redrawChunks();
    this.videoController?.seekToFrame(this.currentFrame);
    return this.currentFrame;
  }

  zoomToCenter = (factor: number) => {

    const viewportWidth = window.innerWidth;
    const viewportHeigth = window.innerHeight;
    this.setZoom(
      this.zoom + factor,
      viewportWidth / 2,
      viewportHeigth / 2
    );
  };

  zoomToPoint = (factor: number, x: number, y: number) => {
    this.setZoom(this.zoom * factor, x, y);
  };


  setZoom = (zoomValue: number, x: number, y: number) => {
    const newZoom = Math.min(Math.max(0.35, zoomValue), 4);

    const point = new Point(x, y)
    const canvasPoint = screenToCanvas(point, this.offset, this.zoom);

    this.offset = point.subtract(canvasPoint.multiply(this.zoom))

    this.zoom = newZoom;
    this.redrawChunks();
  };

centerToPoint(point: Point) {
  this.offset = new Point(
    Math.min(window.innerWidth, this.canvas.width) / 2 - point.x,
    Math.min(window.innerHeight, this.canvas.height) / 2 - point.y
  );
}
  focusSelectedChunk() {
    if (!this.selectedChunk) {
      return;
    }
   
    const chunk = this.selectedChunk;

    const startPosition = chunk.pathStartPosition;
    this.centerToPoint(startPosition.multiply(this.zoom))


    this.redrawChunks();
  }

  redrawChunks() {
    // Clear the original canvas
    this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw the grid on the unscaled canvas
    drawGrid(this.ctx!, this.canvas.width, this.canvas.height, this.offset, this.zoom);

    // Apply pan and zoom transformations
    this.ctx?.save();
    this.ctx?.translate(this.offset.x, this.offset.y);
    this.ctx?.scale(this.zoom, this.zoom);

    let floorChunks = this.chunkManager.getAllChunksInFloor(this.currentFloor);

    const selectedChunkCurrentFloorIndex = floorChunks.indexOf(
      this.selectedChunk
    );
    if (selectedChunkCurrentFloorIndex !== -1) {
      // Remove the selected chunk from its current position
      let selectedChunk = floorChunks.splice(
        selectedChunkCurrentFloorIndex,
        1
      )[0];

      // Add the selected chunk back to the end of the array
      floorChunks.push(selectedChunk);
    }
  
    // First loop to draw all the images
    floorChunks.forEach((chunk) => {
      this.ctx?.save();

      this.ctx?.translate(chunk.position.x * this.zoom, chunk.position.y * this.zoom);

      this.ctx?.translate(
        chunk.rotationPoint.x * this.zoom,
        chunk.rotationPoint.y * this.zoom
      );

      // Rotate the context
      this.ctx?.rotate((chunk.globalRotation * Math.PI) / 180);

      // Translate back
      this.ctx?.translate(
        -chunk.rotationPoint.x * this.zoom,
        -chunk.rotationPoint.y * this.zoom
      );

      // Draw the image

      // Create an off-screen canvas
      let offScreenCanvas = document.createElement("canvas");
      let offScreenCtx = offScreenCanvas.getContext("2d");

      // Set the dimensions of the off-screen canvas
      offScreenCanvas.width = chunk.width;
      offScreenCanvas.height = chunk.height;

      // Draw the image onto the off-screen canvas
      offScreenCtx?.drawImage(chunk.canvas, 0, 0);

      // In the redrawChunks method
      if (this.selectedChunk) {
        if (this.chunkManager.getIndexForChunk(chunk) < this.chunkManager.getIndexForChunk(this.selectedChunk)) {
          if (this.ctx?.globalAlpha !== 1.0) {
            this.ctx.globalAlpha = 1.0;
          }
        } else if (this.chunkManager.getIndexForChunk(chunk) === this.chunkManager.getIndexForChunk(this.selectedChunk)) {
          if (this.ctx?.globalAlpha !== this.opacity) {
            this.ctx.globalAlpha = this.opacity;
          }
        } else {
          if (this.ctx?.globalAlpha !== 0.1) {
            this.ctx.globalAlpha = 0.1;
          }
        }
      } else {
        if (this.ctx?.globalAlpha !== 1.0) {
          this.ctx.globalAlpha = 1.0;
        }
      }
      // Draw the off-screen canvas onto the main canvas
      this.ctx?.drawImage(
        offScreenCanvas,
        0,
        0,
        chunk.width * this.zoom,
        chunk.height * this.zoom
      );

      this.ctx?.restore();
    });

    // Second loop to draw all the lines
    floorChunks.forEach((chunk) => {
      this.ctx?.save();

      this.ctx?.translate(chunk.position.x * this.zoom, chunk.position.y * this.zoom);

      this.ctx?.translate(
        chunk.rotationPoint.x * this.zoom,
        chunk.rotationPoint.y * this.zoom
      );

      // Rotate the context
      this.ctx?.rotate((chunk.globalRotation * Math.PI) / 180);

      // Translate back
      this.ctx?.translate(
        -chunk.rotationPoint.x * this.zoom,
        -chunk.rotationPoint.y * this.zoom
      );

      drawLineOnChunk(
        this.ctx!,
        this.zoom,
        chunk,
        getChunkColor(this.chunkManager.getIndexForChunk(chunk)),
        chunk === this.selectedChunk
      );
      this.ctx?.restore();

    });
    const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);

    drawCameraFrustum(this.ctx!, this.zoom, frameChunk!, this.currentFrame);
    this.ctx?.restore();
  }

  exportData() {
    const newArkitData = this.dataCalculator.rotateArkitData(
      this.data.arKitData,
      this.chunkManager.chunks
    );

    const newFixroundFixes = this.chunkManager.getAllChunks().map((chunk) => {
      return {
        frameNumber: chunk.firstFrameNumber,
        rotationDeg: Number(chunk.rotation.toFixed(3)),
        translationM: [chunk.shift.x / 100.0, 0, chunk.shift.y / 100.0].map(
          (f) => Number(f.toFixed(3))
        ),
        source: "sourceFixTool",
      };
    });

    const fixRounds = this.data.arKitData.fixRounds;
    const newFixRound = {
      fixRoundNum: fixRounds.length,
      type: "manualSourceFix",
      fixes: newFixroundFixes.filter(
        (fix) =>
          fix.rotationDeg !== 0 ||
          fix.translationM[0] !== 0 ||
          fix.translationM[1] !== 0
      ),
    };

    return { ...newArkitData, fixRounds: [...fixRounds, newFixRound] };
  }
}
export default ImageChunkMover;
